import React, { FC, useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import LangContext from "@contexts/lang"
import { SanityPage, sanity_typename } from "@utils/types"
import BlockContent from "@sanity/block-content-to-react"
import SideCard from "../../common/sideCard/index"
import s from "./CommunityArticle.module.scss"
import formatMessage from "@utils/texts"
import SEO from "@components/common/seo/seo"

//Components
type Props = {
  data: { community: SanityPage }
  pageContext: {slug: String}
}

const CommunityArticle: FC<Props> = ({ data: { community }, pageContext:{slug}}) => {
  const [hasMounted, setHasMounted] = useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  const { lang } = React.useContext(LangContext)


  const sanityArticle = community?.content?.filter(singleArticle=>singleArticle?.collectionArticles[0]?.slug?.current === slug)[0]?.collectionArticles[0]

  let articles = community?.content?.map(singleArticle => singleArticle?.collectionArticles[0])

  let firstTag = sanityArticle?.tags?.split("/")[0]

  let articlesSharingSameTag = articles?.filter(blog =>
    blog?.tags?.toLowerCase().includes(firstTag?.toLowerCase())
  ).filter(sameTagArticles=>sameTagArticles?.slug.current !== slug)

  const blogUrl = hasMounted && window?.location
  const [relatedArticles] = useState(articlesSharingSameTag)
  return (
    <>
      <SEO title={`${community.title[lang]} | ${sanityArticle?.title}`} location={blogUrl} />
      <div className={s.articlePageContainer}>
        <div className={s.articleContainer}>
          <div className={s.authorSection}>
            <div>
              <Img
                fluid={sanityArticle?.authorImage?.asset.fluid}
                className={s.authorImage}
                objectFit="contain"
              />
            </div>
            <div>
              <p className={s.authorName}>{sanityArticle?.author}</p>
              <p className={s.articleDate}>{sanityArticle?.date}</p>
            </div>
          </div>
          <div className={s.articleSection}>
            <div>
              <Img
                fluid={sanityArticle?.image?.asset.fluid}
                objectFit="contain"
                className={s.articleImage}
              />
            </div>
            <article dir="auto">
              <BlockContent blocks={sanityArticle?._rawCont} />
            </article>
          </div>
          <div className={s.cssServingSection}></div>
        </div>
        <div className={s.relatedArticleSectioncontainer}>
          <p className={s.relatedArticleSectionTitle}>
            {" "}
            {formatMessage({
              id: "relatedArticles",
              lang,
            })}{" "}
          </p>
          <div className={s.relatedArticleCardsContainer}>
            <div className={s.relatedArticleCard}>
              {relatedArticles.slice(0, 2).map(article => (
                <SideCard data={article} first={true} />
              ))}
            </div>
            <div className={s.relatedArticleCard}>
              {relatedArticles.slice(2, 4).map(article => (
                <SideCard data={article} first={true} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommunityArticle

export const query = graphql`
  query {
    community: sanityPage(slug: { current: { eq: "/community" } }) {
      title {
        en
        ar
      }
      content {
        ...SanityPostRichSection
      }
    }
  }
`
